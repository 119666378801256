import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { BsTypeH6 } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { BiUnderline } from 'react-icons/bi';

function contact() {
  var str = "For any info";
  // console.log("Test", str.toUpperCase());
  return (
    <> 
<Card>
        <Card.Header><span style={{ fontWeight: 'bold' }}  >Contact Us</span></Card.Header>
        <Card.Body>
          <Card.Title>If you want to contribute as volenteer</Card.Title>
          <Card.Text>
          Please write to us at <span style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }}  > help@helpin.in</span> with,<br />
           Your Name:<br />Your contact number :<br /> Your email ID :<br /> Your ideas in detail :<br /> Time per month you can contribute :<br />
           <br /><br />
<h5>For partnering with us :</h5> Your Organisation Name :<br />Contact Person Name :<br />Your contact number :<br /> Your email ID :<br /> Organisation Address :<br /> Your ideas in details :<br /><br /><br />

As we raise by lifting others, we have acquired 80G certificate to benefit our donors in TAX SAVING.
          </Card.Text>
        </Card.Body>
      </Card>
      </>
  );
}

export default contact;
