import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./componet/header";
import Footer from './componet/footer';
import FroentPage from "./pages/froentPage";
import Contact from "./pages/contact";
//import Soon from "./componet/Soon";
import AboutUs from './pages/aboutUs';
import Donate from "./pages/donate";
import { Container } from 'react-bootstrap';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
    <Router>
      <Header />
      <Container fluid     style={{ marginTop: '96px', backgroundColor:'gray' }} >
        <Routes>
          <Route path="/" element={<FroentPage />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Donate" element={<Donate />} />
          <Route path="/Contact" element={<Contact />} />          
        </Routes>
      </Container>
      <Footer />
    </Router>
    </>
  );
}

export default App;
